<script lang="ts">
  import { flip } from "svelte/animate";
  import { fly } from "svelte/transition";

  import { portal, removeToast, toasts } from "$lib/toast";
  import Toast from "./Toast.svelte";
</script>

<div use:portal>
  <div
    class="fixed inset-0 z-20 pointer-events-none flex justify-end px-6 py-14"
  >
    <div class="w-full max-w-md">
      {#each $toasts as { id, data } (id)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="mb-2"
          on:click={() => removeToast(id)}
          animate:flip={{ duration: 500 }}
          transition:fly={{ x: 360, duration: 500 }}
        >
          <Toast
            {id}
            kind={data.kind}
            message={data.message}
            action={data.action}
            on:action={data.onAction ?? (() => {})}
          />
        </div>
      {/each}
    </div>
  </div>
</div>
